import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import classes from "styles/components/Section.module.scss";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  innerClassName?: string;
}

const Section = ({ children, className, innerClassName, ...props }: Props) => {
  return (
    <div {...props} className={[classes.container, className].join(" ")}>
      <div className={[classes.innerContainer, innerClassName].join(" ")}>
        {children}
      </div>
    </div>
  );
};

export default Section;
