import {
  ENFT,
  HeroVideoCover,
  MintableDark,
  MintableLight,
  OpenseaDark,
  OpenseaLight,
  PlayIcon,
  RaribleDark,
  RaribleLight,
} from "assets";
import { useTheme } from "contexts/theme";
import { NextPage } from "next";
import Link from "next/link";
import classes from "styles/components/Home/Hero.module.scss";
import Headline from "components/Home/Headline";
import GradientMain from "../GradientMain";
import { useState } from "react";

interface Props {}

const platforms = [
  { Light: OpenseaLight, Dark: OpenseaDark, key: "OpenSea" },
  { Light: RaribleLight, Dark: RaribleDark, key: "Rarible" },
  { Light: MintableLight, Dark: MintableDark, key: "Mintable" },
];

const Hero: NextPage<Props> = () => {
  const { isDark } = useTheme();
  const [isClosed, setIsClosed] = useState(false);

  const isLoggedIn =
    typeof window !== "undefined" && localStorage.getItem("apiToken")
      ? true
      : false;

  return (
    <GradientMain className={classes.outerContainer}>
      {!isClosed && <Headline handleClick={() => setIsClosed(true)}/>}
      <div
        className={[
          classes.container,
          isClosed && classes.noHeadline
        ].join(" ")}
      >
        <div className={classes.top}>
          <div className={classes.left}>
            <h1>
              Secure your digital work with copyrights.
            </h1>
            <p className={classes.tags}>
              Make more money with an eNFT™ by specifying
              <br /> what rights you are selling along with
              <br /> your NFTs on the blockchain.
            </p>
            <p
              className={classes.price}
              style={{
                color: isDark ? "#CFCFCF" : "#403F3F",
              }}
            >
              Create an eNFT™ for{" "}
              <span
                style={{
                  color: isDark ? "#6BEABC" : "#D01971",
                }}
              >
                $50
              </span>
            </p>
            <div className={classes.mobImg}>
              <div className={classes.box}>
                <HeroVideoCover />
                {/* <div className={classes.play}>
                  <PlayIcon />
                </div> */}
              </div>
            </div>
            <Link href={isLoggedIn ? "/create?step=1" : "/login?redirect=create"}>
              <button>Start Now</button>
            </Link>
          </div>
          <div className={classes.right}>
            <div className={classes.box}>
              <HeroVideoCover />
              {/* <div className={classes.play}>
                <PlayIcon />
              </div> */}
            </div>
          </div>
        </div>
        <div className={classes.bottom}>
          <div className={classes.text}>
            {/* <p
              style={{
                color: isDark ? "#CFCFCF" : "#D01971",
              }}
            >
              Transform your NFTs to eNFTs™
            </p> */}
            <p>
              <ENFT/>
            </p>
            <p
              style={{
                color: isDark ? "#CFCFCF" : "#3F3F3F",
              }}
            >
              Compatible with all NFT Marketplaces including
            </p>
          </div>
          <div className={classes.platforms}>
            {platforms.map(({ Dark, Light, key }) =>
              isDark ? <Dark key={key} /> : <Light key={key} />
            )}
          </div>
        </div>
      </div>
    </GradientMain>
  );
};

export default Hero;
