import {
  ConnectIcon,
  DiligenceIcon,
  RemintIcon,
  TrademarkiaLogo,
  ValidateIcon,
} from "assets";
import Section from "components/Section";
import { Advantage } from "interfaces";
import React from "react";
import classes from "styles/components/Home/Advantages.module.scss";
import AdvantageCard from "./AdvantageCard";

const advantages: Advantage[] = [
  {
    icon: ConnectIcon,
    title: "Connect",
    description:
      "Connect your existing NFTs through a digital wallet, or upload digital files for a NFT you wish to create.",
  },
  {
    icon: ValidateIcon,
    title: "Validate",
    description:
      "Upload evidence you have that shows any underlying transfer of rights, or request us to prepare assignment documents.",
  },
  {
    icon: DiligenceIcon,
    title: "Diligence",
    description:
      "Our skilled network of copyright specialists will audit & verify your NFT claims & prepare any needed documentation for e-sign.",
  },
  {
    icon: RemintIcon,
    title: "Receive eNFT™",
    description:
      "Enforceable will transform your digital assets into valuable intellectual property, by creating eNFTs™.",
  },
];

// TODO: Change this later
export default function Advantages({ showAdvantages = true }) {
  return (
    <Section
      className={classes.container}
      innerClassName={classes.innerContainer}
    >
      {showAdvantages && (
        <div className={classes.helped}>
          From the creators of
          <div className={classes.trademarkia}>
            <TrademarkiaLogo />
          </div>
          , over 100,000 + small business owners helped.
        </div>
      )}
      <h2>Advantages of Enforceable</h2>
      <p className={classes.topDesc}>
        With Enforceable, increase the legitimacy of your NFTs and improve their
        marketability.
        <br />
        Partner with the most trusted name in NFT marketplaces and validation
        services.
      </p>
      <div className={classes.cards}>
        {advantages.map((advantage) => (
          <AdvantageCard key={advantage.title} {...advantage} />
        ))}
      </div>
      <p className={classes.helped}>
        <span>From the creators of</span>
        <span style={{ marginBottom: "10px" }} className={classes.trademarkia}>
          <TrademarkiaLogo />
        </span>
        <span>, over 100,000 + small business owners helped.</span>
      </p>
      {showAdvantages && (
        <div className={classes.cards}>
          {advantages.map((advantage) => (
            <AdvantageCard key={advantage.title} {...advantage} />
          ))}
        </div>
      )}
    </Section>
  );
}
