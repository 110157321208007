import { NextPage } from "next";
import classes from "styles/components/GradientMain.module.scss";
import Section from "./Section";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const GradientMain: NextPage<Props> = ({ className, children, ...props }) => {
  return (
    <>
      <Section
        className={classes.container}
        innerClassName={classes.innerContainer}
      >
        <div {...props} className={[classes.children, className].join(" ")}>
          {children}
        </div>
      </Section>
    </>
  );
};

export default GradientMain;
