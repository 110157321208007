import { client } from "config/axiosClient";

export const getNews = async () => {
  try {
    const response = await client.get("/news/all");
    const newsItems = [];
    response.data.body.forEach((item) => {
      newsItems.push({
        title: item.headline,
        url: item.article_url,
      });
    });
    return newsItems;
  } catch (error) {
    throw error;
  }
};
