import { ModalClose } from "assets";
import { NextPage } from "next";
import { getNews } from "services/news";
import classes from "styles/components/Home/Headline.module.scss";
import Link from "next/link";
import { useEffect, useState } from "react";

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

interface Props {
  handleClick: () => void
}

interface News {
  title: string
  url: string
}

const Headline: NextPage<Props> = ({ handleClick }) => {
  const [newsIdx, setNewsIdx] = useState(0)
  const [news, setNews] = useState<News[]>([{
    title: '',
    url: ''
  }])

  useEffect(() => {
    getNews()
      .then((newsItems) => {
        const someNews = newsItems.sort(() => Math.random() - 0.5)
        setNews(someNews.slice(0, 5))
        // setNews(res[Math.floor(Math.random() * res.length)])
      })
      .catch((err) => {
        handleClick()
        console.error(err)
      });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.contentContainer}>
          <p className={classes.trending}>🔥 Trending</p>
          <div className={classes.info}>
            <Carousel
              onChange={(idx) => setNewsIdx(idx)}
              autoPlay={true}
              infiniteLoop={true}
              interval={3000}
              emulateTouch={true}
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
            >
              {news.map((item, index) => (
                <div key={index}><p>{item.title}</p></div>
              ))}
            </Carousel>
          </div>
          <div className={classes.right}>
            <a href={news[newsIdx].url} className={classes.knowMore}>
              Know More
            </a>
            <div onClick={handleClick} className={classes.close}>
              <ModalClose />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headline;

/*
http://react-responsive-carousel.js.org/storybook/index.html?path=/story/01-basic--base&knob-showArrows_Toggles=&knob-showStatus_Toggles=&knob-showIndicators_Toggles=true&knob-infiniteLoop_Toggles=true&knob-showThumbs_Toggles=&knob-useKeyboardArrows_Toggles=&knob-autoPlay_Toggles=true&knob-stopOnHover_Toggles=true&knob-swipeable_Toggles=true&knob-dynamicHeight_Toggles=&knob-emulateTouch_Toggles=true&knob-autoFocus_Toggles=&knob-thumbWidth_Values=100&knob-selectedItem_Values=0&knob-interval_Values=2000&knob-transitionTime_Values=500&knob-swipeScrollTolerance_Values=5
*/