import GradientBorder from "components/GradientBorder";
import { Advantage } from "interfaces";
import { NextPage } from "next";
import classes from "styles/components/Home/AdvantageCard.module.scss";

interface Props extends Advantage {}

const AdvantageCard: NextPage<Props> = ({ icon: Icon, title, description }) => {
  return (
    <div className={classes.grad}>
      <div className={classes.container}>
        <div className={[classes.icon, title === "Re Mint" && classes.remint].join(" ")}>
          <Icon />
        </div>
        <div className={classes.details}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default AdvantageCard;
