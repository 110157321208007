import Advantages from "components/Home/Advantages";
import Hero from "components/Home/Hero";
import Plans from "components/Home/Plans";
import Section from "components/Section";
import { NextPage } from "next";

interface Props {}

const Home: NextPage<Props> = () => {
  return (
    <>
      <Hero />
      <Advantages showAdvantages={false} />
    </>
  );
};

export default Home;
